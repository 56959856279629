'use strict';

angular.module('cpformplastApp.accounts')
  .controller('AccountsManagementController', function ($scope, User, $state) {

    $scope.users = User.query();

    $scope.userTableButtons = [{
      'icon':'plus',
      'title':'Ajouter',
      'function': () => {
        $state.go('accounts/account-creation');
      }
    }];

    $scope.userTableStructure = [{
      'title':'Nom',
      'value':'name',
      'filter':'alpha'
    },{
      'title':"Nom d'utilisateur",
      'value':'username',
      'filter':'alpha'
    },{
      'title':'Rôle',
      'value':'role',
      'filter':'alpha'
    }];

    $scope.selectUser = function(user) {
      $state.go('accounts/account-creation', {'userId': user._id});
    };

});
